<template>
  <div class="home">
    <places-list></places-list>
  </div>
</template>

<script>
import PlacesList from "@/components/PlacesList.vue";

export default {
  name: "HomeView",
  components: {
    PlacesList,
  },
};
</script>
