<template>
  <nav>
    <router-link to="/">Wszystkie Miejsca</router-link> |
    <router-link to="/panel">Panel</router-link>
    <span class="wyloguj" @click="logout" v-if="isLoggedIn">Wyloguj</span>
  </nav>

  <router-view />

  <footer>
    <p>contact: <a href="mailto:admin@copotrzeba.pl">admin@copotrzeba.pl</a></p>
  </footer>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from "@firebase/auth";
const auth = getAuth();
export default {
  data() {
    return {
      isLoggedIn: false,
    };
  },
  computed: {
    showLocationMenu() {
      return this.$router.currentRoute.value.name === "home";
    },
  },
  created() {
    this.$store.dispatch("loadPlaces");

    onAuthStateChanged(auth, () => {
      this.isLoggedIn = auth.currentUser;
    });
  },
  methods: {
    async logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.replace({ name: "login" });
      });
    },
  },
};
</script>

<style lang="scss">
.wyloguj {
  background: #0057b7;
  padding: 3px 10px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 95%;
  max-width: 1240px;
  margin: 0 auto;
  padding-bottom: 60px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
  font-size: 12px;

  a {
    color: #0057b7;
  }
}

h1 {
  color: #0057b7;
  font-size: 24px;

  @media only screen and (min-width: 842px) {
    font-size: 40px;
  }
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    padding: 0 20px;
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<style>
*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
