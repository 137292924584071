<template>
  <section class="cart" @click="goToPlace">
    <h1>{{ place.label }}</h1>
    <span class="date">Aktualizowany: {{ readableTime(place.timestamp) }}</span>
    <router-link :to="{ name: 'place', params: { id: place.id } }">Zobacz potrzeby tego miejsca</router-link>
  </section>
</template>

<script>
export default {
  props: {
    place: Object,
  },
  methods: {
    readableTime(timestamp) {
      if (!timestamp) {
        return "nd";
      }
      const date = new Date(timestamp.seconds * 1000);

      return date.toLocaleString();
    },
    goToPlace() {
      this.$router.push({ name: "place", params: { id: this.place.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  flex: 0 0 100%;
  background: rgba(0, 0, 0, 0.06);
  padding: 30px 20px 10px;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin: 0 0 30px 0;
  transition: background-color 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.15) !important;
  }

  @media only screen and (min-width: 640px) {
    flex: 0 0 calc(50% - 20px);
    margin: 20px 10px;
  }

  @media only screen and (min-width: 842px) {
    flex: 0 0 calc(33.33% - 20px);
    margin: 20px 10px;
  }

  h1 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .date {
    font-weight: bold;
  }

  a {
    display: block;
    padding: 20px 0;
    color: #2c3e50;
  }
}
</style>
