import { createStore } from "vuex";
import { db } from "@/firebase/";
import { collection, query, onSnapshot, where } from "firebase/firestore";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      places: {},
    };
  },
  actions: {
    loadPlaces({ commit }) {
      const q = query(collection(db, "places"), where("needs", "!=", []));

      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          commit("addPlace", doc);
        });
      });
    },
  },
  mutations: {
    addPlace(state, doc) {
      const obj = { ...doc.data(), id: doc.id };
      state.places[doc.id] = obj;
    },
  },
});

export default store;
