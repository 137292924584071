<template>
  <section class="carts_list">
    <places-list-cart v-for="place in places" :key="place.id" :place="place"></places-list-cart>
    <section class="cart_more">
      <h1>Dodaj swoje miejsce</h1>
      <p><strong>Napisz maila na admin@copotrzeba.pl</strong></p>
      <p>
        Bardzo proszę o kontakt tylko koordynatorów z danego miejsca lub osoby przez nie oddelegowane do zarządzania
        zasobami.
      </p>
    </section>
  </section>
</template>

<script>
import { mapState } from "vuex";
import PlacesListCart from "./PlacesListCart.vue";

export default {
  components: {
    PlacesListCart,
  },
  computed: {
    ...mapState(["places"]),
  },
};
</script>

<style lang="scss" scoped>
.carts_list {
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .cart_more {
    flex: 0 0 100%;
    background: rgba(0, 0, 0, 0.03);
    padding: 30px 20px 10px;

    @media only screen and (min-width: 640px) {
      flex: 0 0 100%;
    }

    p {
      max-width: 470px;
    }

    h1 {
      font-size: 18px;
      margin-top: 0;
    }
  }
}
</style>
