import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import { getDatabase, ref, child, get } from "firebase/database";
import { db } from "@/firebase/";
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const auth = getAuth();

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/miejsce/:id",
    name: "place",
    component: () => import(/* webpackChunkName: "place" */ "../views/PlaceNeed.vue"),
    props: true,
  },
  {
    path: "/panel",
    name: "panellist",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/PanelListView.vue"),
  },
  {
    path: "/panel/:id",
    name: "panel",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "panel" */ "../views/PanelView.vue"),
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== "panellist" && to.name !== "panel") {
    next();
    return true;
  }

  if (!auth.currentUser) {
    next("/login");
    return true;
  }

  if (to.name === "panellist") {
    next();
    return true;
  }

  const docRef = doc(db, "places", to.params.id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    console.log(data);
    next();
    // if (data.owners.indexOf(auth.currentUser.email) !== -1) {
    //   next();
    // } else {
    //   next("/");
    // }
  } else {
    // doc.data() will be undefined in this case
    next("/");
  }

  // const dbRef = ref(getDatabase());
  // const placeId = to.params.id;
  // get(child(dbRef, `places/${placeId}`))
  //   .then((snapshot) => {
  //     if (snapshot.exists()) {
  //       const { owners } = snapshot.val();
  //       if (owners.indexOf(auth.currentUser.email) !== -1) {
  //         next();
  //       } else {
  //         next("/");
  //       }
  //     } else {
  //       next();
  //       console.log("No place with this key");
  //     }
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
});

export default router;
